<template>
  <div class="article-container">

    <el-card class="box-card">
      <el-table
        :data="tabledata"
        stripe
        style="width: 100%"
        class="list-table"
        size="mini"
        v-loading="loading"
      >
        <el-table-column
          type="index"
          label="序号"
        >
        </el-table-column>
        <el-table-column
          prop="areaName"
          label="区域名称"
        > </el-table-column>
        <el-table-column
          label="对应FAE名称"
          prop="faePersonLiableName"
        >
        </el-table-column>
        <el-table-column
          label="操作"
          fixed="right"
        >
          <template slot-scope="scope">
            <div>

              <el-tooltip
                content="编辑"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="editFAE(scope.row)"
                  circle
                  icon="el-icon-edit"
                  type="primary"
                ></el-button>
              </el-tooltip>

            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        layout="prev, pager, next"
        background
        :total="totalCount"
        :page-size="form.pageSize"
        :disabled="loading"
        :current-page.sync="form.page"
        @current-change="onCurrentChange"
      />
      <!-- /列表分页 -->
    </el-card>
    <!-- 编辑、添加 -->

    <el-dialog
      :visible.sync="dialogVisible"
      width="30%"
    >
      <div style="font-size: 20px;">区域名称：{{ EditData.areaName }}</div>

      <div style="font-size: 20px;">
        请选择该区域FAE负责人
        <el-select
          v-model="editform.faePersonLiable"
          placeholder="请选择"
        >
          <el-option
            v-for="item in FaeOperatorData"
            :key="item.userId"
            :label="item.name"
            :value="item.userId"
          >
          </el-option>
        </el-select>
      </div>

      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="editallotment"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { GatFaeAllomentPageUrl, getFaeOperatorListUrl ,PutAreaFaeAllotmentUrl} from "@/api/fae";
export default {
  components: {},

  data() {
    return {
      tabledata: [],
      FaeOperatorData: [],
      form: {
        name: null,
        size: 20, // 每页大小
        page: 1, // 当前页码
      },
      editform: {
        faePersonLiable: null,
        id: null,
      },
      dialogVisible: false,
      EditData: {},

      totalCount: 0, // 总数据条数
      status: null, // 查询文章的状态，不传就是全
      channels: [], // 文章频道列表
      channelId: null, // 查询文章的频道
      rangeDate: null, // 筛选的范围日期
      loading: true, // 表单数据加载中 loading
    };
  },
  computed: {},
  watch: {},
  created() {
    this.loadArticles(1);
    this.getFaeList();
  },
  mounted() {},
  methods: {
  
    async editallotment() {
      // 展示加载中 loading
      this.loading = true;
      try {
        const res = await PutAreaFaeAllotmentUrl(this.editform);
        if (res.data.code === 200) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.dialogVisible = false
          this.loadArticles(1);
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    },
    editFAE(row) {
      this.EditData = row;
      this.editform.id = row.id;
      this.dialogVisible = true;
    },
    async getFaeList() {
      // 展示加载中 loading
      this.loading = true;
      try {
        const res = await getFaeOperatorListUrl();
        if (res.data.code === 200) {
          this.FaeOperatorData = res.data.data;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    },

    async loadArticles(page = 1) {
      // 展示加载中 loading
      this.loading = true;
      try {
        const res = await GatFaeAllomentPageUrl(this.form);
        if (res.data.code === 200) {
          this.tabledata = res.data.data.record;
          this.totalCount = res.data.data.total;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    },
    onCurrentChange(page) {
      this.loadArticles(page);
    },
  },
};
</script>

<style scoped lang="less">
.filter-card {
  margin-bottom: 30px;
}

.list-table {
  margin-bottom: 20px;
}

.article-cover {
  width: 60px;
  background-size: cover;
}
.input {
  width: 200px;
  margin-right: 10px;
}
.flexparents {
  margin-bottom: 10px;
}
.el-select {
  width: 150px;
}
.leftgap {
  margin-left: 20px;
}
.rightgap {
  margin-right: 20px;
}
</style>
